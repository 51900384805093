import { template as template_e4094534a915446e9e032b35784e6c6a } from "@ember/template-compiler";
const FKControlMenuContainer = template_e4094534a915446e9e032b35784e6c6a(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
