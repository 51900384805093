import { template as template_d9ee8c1ffa1149f7a01b5a66f9cdcf48 } from "@ember/template-compiler";
const FKText = template_d9ee8c1ffa1149f7a01b5a66f9cdcf48(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
