import { template as template_dbbde6f5c9844875a1ae00a70b94718c } from "@ember/template-compiler";
const FKLabel = template_dbbde6f5c9844875a1ae00a70b94718c(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
